export type EnvironmentName = "mc" | "ff" | "usnat" | "ussec" | "prod";

const sideloadWithDogfoodClientId = false;

const Analyzer: {
    defaultCloud: EnvironmentName;
    instrumentationKey: string;
    serviceClientId: string;
} = (window as any).Analyzer || {
    defaultCloud: "prod",
    serviceClientId: sideloadWithDogfoodClientId ? "bbefd4b6-1079-4122-b895-3fa9271cd994" : "3a95cbc7-54a5-4449-b153-9cc4fc66f609",
    instrumentationKey: "acdb6d9a-2199-4f3b-8adc-0dd1766afbcd"
};

export const defaultCloud = Analyzer.defaultCloud;
export const serviceClientId = Analyzer.serviceClientId;
export const useFirstPartyClientId = serviceClientId === "3a95cbc7-54a5-4449-b153-9cc4fc66f609";
export const instrumentationKey = Analyzer.instrumentationKey;
export const cloud = defaultCloud;

export const corpTenantId = "72f988bf-86f1-41af-91ab-2d7cd011db47";
export const ameTenantId = "33e01921-4d64-4f8c-a055-5bdaffd5e33d";
export const pmeTenantId = "975f013f-7f24-47e8-a7d3-abc4752bf346";

export const cloudDisplayNames: { [key in EnvironmentName]: string; } = {
    prod: "Public Azure (portal.azure.com)",
    ff: "Fairfax (portal.azure.us)",
    mc: "Mooncake (portal.azure.cn)",
    usnat: "USNat (portal.azure.eaglex.ic.gov)",
    ussec: "USSec (portal.azure.microsoft.scloud)",
};
export const cloudDisplayName = cloudDisplayNames[cloud];
