import { CommandBar, Dropdown, IButtonStyles, ICommandBarItemProps, ICommandBarStyles, IconButton, IDropdownOption, mergeStyleSets, Panel, PanelType, SelectionMode, Text } from "@fluentui/react";
import { useContext, useMemo } from "react";
import { DataGrid, DataGridColumn } from "../Components/DataGrid";
import { loginRedirect, logoutRedirect, tenantIdNameMap, TenantName } from "../Common/Auth";
import { AccountInfo } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { DataAccessContext } from "../Contexts/DataAccessContext";

interface AccountItem {
    key: string;
    name: string;
    tenantName: TenantName;
    account: AccountInfo;
}

export const AccountsPanel = (props: {
    dismissPanel: () => void;
}) => {
    const { accounts } = useMsal();
    const { activeAccount, setActiveAccount } = useContext(DataAccessContext);
    const { gridItems, dropdownOptions } = useMemo(() => {
        const gridItems = accounts.map(x => {
            return {
                key: x.username,
                name: x.username,
                tenantName: tenantIdNameMap[x.tenantId] || x.tenantId,
                account: x
            } as AccountItem;
        });
        const dropdownOptions = gridItems.map(x => {
            const item: IDropdownOption = {
                key: x.account.username,
                text: `${x.tenantName} (${x.account.username})`,
                data: x.account
            };
            return item;
        });
        return { gridItems, dropdownOptions };
    }, [accounts]);

    return (
        <Panel
            headerText="Manage Accounts"
            isOpen
            onDismiss={props.dismissPanel}
            closeButtonAriaLabel="Close"
            type={PanelType.medium}
            isLightDismiss
        >
            <Dropdown
                label="Primary account"
                options={dropdownOptions}
                selectedKey={activeAccount?.username}
                onChange={(_, option) => {
                    if (option) {
                        setActiveAccount(option.data);
                    }
                }}
            />
            <h3 className={classNames.header}>Signed-in accounts</h3>
            <DataGrid<AccountItem>
                items={gridItems}
                columns={columns}
                selectionMode={SelectionMode.none}
            />
            <CommandBar
                items={commandItems}
                styles={commandBarStyles}
            />
        </Panel>
    );
}

const columns: DataGridColumn<AccountItem>[] = [
    {
        key: "tenantName",
        name: "Tenant",
        onRender: item => <Text>{item.tenantName}</Text>,
        getSortKey: item => item.tenantName,
        minWidth: 100,
        maxWidth: 100
    },
    {
        key: "name",
        name: "Username",
        onRender: item => <Text>{item.name}</Text>,
        getSortKey: item => item.name,
        minWidth: 100
    },
    {
        key: "signOut",
        name: "Sign out",
        onRender: item => {
            return (
                <IconButton
                    iconProps={{ iconName: "SignOut" }}
                    onClick={() => {
                        logoutRedirect(item.account);
                    }}
                    styles={signOutButtonStyles}
                />
            );
        },
        minWidth: 70,
    }
];

const commandItems: ICommandBarItemProps[] = [
    {
        key: "addAccount",
        text: "Add account",
        iconProps: { iconName: "Add" },
        onClick: () => {
            loginRedirect()
        },
    }
];

const signOutButtonStyles: IButtonStyles = {
    root: {
        height: 20
    }
}

const commandBarStyles: ICommandBarStyles = {
    root: {
        padding: 0,
        marginBottom: -10
    }
}

const classNames = mergeStyleSets({
    list: {
        listStyleType: "initial",
        marginLeft: 20
    },
    header: {
        fontWeight: 600,
        marginTop: 20,
        marginBottom: 0
    }
});
