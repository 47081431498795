import { MsalProvider } from "@azure/msal-react";
import React, { ErrorInfo } from "react";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import App from "./App";
import { initializeMsal, msalInstance } from "./Common/Auth";
import { ErrorBoundary } from "./Components/ErrorBoundary";
import { ErrorModule } from "./Components/ErrorModule";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { appInsights, reactPlugin } from "./AppInsights";

appInsights.trackPageView();

const renderError = (error: Error, errorInfo: ErrorInfo) => <ErrorModule error={error} errorInfo={errorInfo} />;

initializeMsal().then(() => {
    ReactDOM.render(
        <React.StrictMode>
            <ErrorBoundary renderError={renderError}>
                <AppInsightsContext.Provider value={reactPlugin}>
                    <MsalProvider instance={msalInstance}>
                        <App />
                    </MsalProvider>
                </AppInsightsContext.Provider>
            </ErrorBoundary>
        </React.StrictMode>,
        document.getElementById("root")
    );
});
