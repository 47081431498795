import { AccountInfo, BrowserAuthError, BrowserAuthErrorCodes, PublicClientApplication } from "@azure/msal-browser";
import { TokenCredential } from "@azure/identity";
import { ameTenantId, corpTenantId, pmeTenantId, serviceClientId } from "./Environment";

const aadConfig = {
    "bbefd4b6-1079-4122-b895-3fa9271cd994": {
        authority: "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
        defaultScope: "api://bbefd4b6-1079-4122-b895-3fa9271cd994/Analyzer.Read"
    },
    "3a95cbc7-54a5-4449-b153-9cc4fc66f609": {
        authority: "https://login.microsoftonline.com/common",
        defaultScope: "api://3a95cbc7-54a5-4449-b153-9cc4fc66f609/AzurePortalTelemetry.Read"
    }
}[serviceClientId]!;

export type TenantName = "AME" | "PME" | "Corp";

export const tenantIdNameMap: Record<string, TenantName> = {
    [ameTenantId]: "AME",
    [pmeTenantId]: "PME",
    [corpTenantId]: "Corp"
}

export const msalInstance = new PublicClientApplication({
    auth: {
        clientId: serviceClientId,
        authority: aadConfig.authority,
        redirectUri: window.location.origin
    },
    cache: {
        cacheLocation: "localStorage",
    }
});

export async function initializeMsal() {
    await msalInstance.initialize();
    msalInstance.enableAccountStorageEvents();
}

export async function loginRedirect(isRetry?: boolean): Promise<void> {
    try {
        await msalInstance.loginRedirect({
            scopes: ["openid", "profile"],
            prompt: "select_account"
        });
    } catch (error) {
        if (!isRetry && ((error as BrowserAuthError).errorCode === BrowserAuthErrorCodes.interactionInProgress)) {
            return loginRedirect(true);
        }

        throw error;
    }
}

export async function logoutRedirect(account: AccountInfo, isRetry?: boolean): Promise<void> {
    try {
        await msalInstance.logoutRedirect({
            account,
            postLogoutRedirectUri: window.location.href,
        });
    } catch (error) {
        const itemKey = 'msal.interaction.status';
        if (!isRetry
            && ((error as BrowserAuthError).errorCode === BrowserAuthErrorCodes.interactionInProgress)
            && sessionStorage.getItem(itemKey)) {
            sessionStorage.removeItem(itemKey);
            return logoutRedirect(account, true);
        }

        throw error;
    }
}

export async function handleRedirectPromise() {
    try {
        await msalInstance.handleRedirectPromise()
    } catch (error) {
        if ((error as BrowserAuthError).errorCode !== BrowserAuthErrorCodes.noTokenRequestCacheError) {
            throw error;
        }
    }
}

export async function acquireToken(options?: { scopes?: string | string[], corpOnly?: boolean }) {
    const scopes = options?.scopes || aadConfig.defaultScope;
    const scopesArray = typeof scopes === "string" ? [scopes] : scopes;
    let account: AccountInfo | null = null;
    if (options?.corpOnly) {
        const activeAccount = msalInstance.getActiveAccount();
        account = activeAccount?.tenantId === corpTenantId
            ? activeAccount
            : msalInstance.getAllAccounts().find(x => x.tenantId === corpTenantId) || null;
        if (!account) {
            throw new Error("Please add a Corp account to complete this request.");
        }
    } else {
        account = msalInstance.getActiveAccount();
        if (!account) {
            throw new Error("Please select a primary account to complete this request.");
        }
    }

    try {
        return await msalInstance.acquireTokenSilent({
            scopes: scopesArray,
            account
        });
    } catch (e) {
        await msalInstance.acquireTokenRedirect({
            scopes: scopesArray,
            account
        });
        throw e;
    }
}

export const corpTokenCredential: TokenCredential = {
    getToken: async (scopes) => {
        const { accessToken, extExpiresOn } = await acquireToken({ scopes, corpOnly: true });
        return {
            token: accessToken,
            expiresOnTimestamp: extExpiresOn!.getTime()
        }
    }
};

export const activeAccountTokenCredential: TokenCredential = {
    getToken: async (scopes) => {
        const { accessToken, extExpiresOn } = await acquireToken({ scopes });
        return {
            token: accessToken,
            expiresOnTimestamp: extExpiresOn!.getTime()
        }
    }
};
