import { AccountInfo } from "@azure/msal-browser";
import { defaultKusto } from "./Kusto";
import { eudbTenantIds } from "../Contexts/DataAccessContext";

const useV2Table = !!defaultKusto.portalEuServer;
const forceV2Table = process.env.NODE_ENV === "development" && true;

function doMicroExpand(activeAccount: AccountInfo | null) {
    return useV2Table && (forceV2Table || (activeAccount && eudbTenantIds.has(activeAccount.tenantId)));
}

export function getPortalTable(options: {
    database: "AzurePortal" | "AzPtlCosmos",
    table: "ClientTelemetry" | "ClientEvents" | "ExtTelemetry" | "ExtEvents" | "SvcSessions",
    activeAccount: AccountInfo | null,
}) {
    const { database, table, activeAccount } = options;
    return doMicroExpand(activeAccount) ? `X.database('${database}').${table}` : table;
}

export function getPortalQuery(options: { baseQuery: string, groupQuery: string, beforeQuery?: string, activeAccount: AccountInfo | null }) {
    const { baseQuery, groupQuery, beforeQuery, activeAccount } = options;
    let query: string = beforeQuery || "";
    if (doMicroExpand(activeAccount)) {
        query += `macro-expand isfuzzy=true force_remote=true AzPortalPartnerEG as X
(
${baseQuery.split("\n").map(line => `    ${line}`).join("\n")}
)`;
    } else {
        query += baseQuery
    }

    if (groupQuery) {
        query += `
${groupQuery}`;
    }

    return query;
}
