import React from 'react';
import { defaultKusto, cloudKusto, getCloudKustoConfig } from '../Common/Kusto';
import './KustoLinks.css';

interface Props {
  server?: keyof KustoServers;
  database?: keyof KustoDatabases;
  query?: string;
  prodOnly?: boolean;
  cloud?: EnvironmentName;
}

const KustoLinks = (props: Props) => {
  const { server, database, query, prodOnly, cloud } = props;
  if (!(server && database && query)) {
    return null;
  }

  const kustoConfig = cloud
    ? getCloudKustoConfig(cloud)
    : prodOnly ? defaultKusto : cloudKusto;
  const encodedQuery = encodeURIComponent(query!);
  const kustoLink = `${kustoConfig.resourceFormat.replace("{0}", kustoConfig[server!] as string)}:443/${kustoConfig[database!] || defaultKusto[database!]}?query=${encodedQuery}&web=${cloud === "prod" ? 1 : 0}`;
  const kustoLinkSaw = `${kustoLink}&saw=1`;
  return (
    <div className="fxs-controls-kustolinks">
      [<a target="_blank" href={kustoLink} rel="noopener noreferrer">Run in Kusto.Explorer</a>]
      [<a target="_blank" href={kustoLinkSaw} rel="noopener noreferrer">Run in Kusto.Explorer on SAW</a>]
    </div>
  );
};

export default React.memo(KustoLinks);
