import { AccountInfo, EventType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { handleRedirectPromise, loginRedirect, msalInstance } from "../Common/Auth";
import { ajaxWithAuth } from "../Common/Ajax";
import { ErrorMessageBar } from "../Common/ErrorMessageBar";
import SplashScreen from "../Layout/SplashScreen";

interface DataAccessContextValue {
    activeAccount: AccountInfo | null;
    corpAccount: AccountInfo | null;
    includeEudbPortalTelemetry: boolean;
    auth: { success?: boolean; error?: any; } | undefined;
    setActiveAccount: (account: AccountInfo) => void;
}

export const eudbTenantIds = new Set([
    "33e01921-4d64-4f8c-a055-5bdaffd5e33d", // AME
    "975f013f-7f24-47e8-a7d3-abc4752bf346" // PME
])

export const DataAccessContext = createContext<DataAccessContextValue>({
    activeAccount: null,
    corpAccount: null,
    includeEudbPortalTelemetry: false,
    auth: undefined,
    setActiveAccount: null as any
});

export const DataAccessContextProvider = (props: PropsWithChildren<{}>) => {
    const { accounts } = useMsal();
    const [activeAccount, setActiveAccountState] = useState(() => msalInstance.getActiveAccount());
    const [auth, setAuth] = useState<{ success?: boolean; error?: any; }>();
    useEffect(() => {
        const callbackId = msalInstance.addEventCallback((message) => {
            if (message.eventType === EventType.ACTIVE_ACCOUNT_CHANGED) {
                setActiveAccountState(msalInstance.getActiveAccount());
            }
        });
        return () => {
            callbackId && msalInstance.removeEventCallback(callbackId);
        };
    }, []);
    const setActiveAccount = useCallback((account: AccountInfo) => {
        setActiveAccountState(account);
        msalInstance.setActiveAccount(account);
    }, []);
    useEffect(() => {
        let disposed = false;
        handleRedirectPromise().then(() => {
            if (!disposed && !activeAccount) {
                if (msalInstance.getActiveAccount()) {
                    setActiveAccountState(msalInstance.getActiveAccount());
                } else {
                    const newActiveAccount = accounts.find(x => eudbTenantIds.has(x.tenantId)) || accounts[0];
                    if (newActiveAccount) {
                        setActiveAccount(newActiveAccount);
                    } else {
                        loginRedirect();
                    }
                }
            }
        });
        return () => { disposed = true; };
    }, [activeAccount, accounts, setActiveAccount]);
    const includeEudbPortalTelemetry = useMemo(() => {
        return activeAccount ? eudbTenantIds.has(activeAccount?.tenantId) : false;
    }, [activeAccount]);
    const corpAccount = useMemo(() => {
        return accounts.find(x => x.tenantId === "72f988bf-86f1-41af-91ab-2d7cd011db47") || null;
    }, [accounts]);
    useEffect(() => {
        let disposed = false;
        if (activeAccount) {
            ajaxWithAuth("/api/checkAccess")
                .then(() => { !disposed && setAuth({ success: true }) })
                .catch(error => {
                    if (!disposed) {
                        setAuth({ error });
                    }
                });
        }

        return () => { disposed = true; };
    }, [activeAccount]);
    return (
        <DataAccessContext.Provider value={{ activeAccount, corpAccount, includeEudbPortalTelemetry, auth, setActiveAccount }}>
            {activeAccount && auth
                ? auth.success
                    ? props.children
                    : <ErrorMessageBar error={auth.error} />
                : <SplashScreen />}
        </DataAccessContext.Provider>
    )
};
