import { ajaxWithAuth } from "./Ajax";
import { cloud, defaultCloud } from "./Environment";

const map: StringMap<KustoConfig> = {
    prod: {
        displayName: "Public Azure",
        resourceFormat: "https://{0}.kusto.windows.net",
        portalServer: "azportalpartnerrow.westus",
        portalEuServer: "azportalpartnerrow.westeurope",
        oldPortalServer: "azportalpartner",
        portalDatabase: "AzurePortal",
        portalCosmosDatabase: "AzPtlCosmos",
        armServer: "armprodgbl.eastus",
        armDatabase: "ARMProd",
        iaasTestServer: "iaasexptestartifacts.westus2",
        iaasTestDatabase: "TypeScriptTests",
        cloudTestServer: "Ctest",
        cloudTestProdDatabase: "CloudTestProd",
        oneEsServer: "1es",
        oneEsAzureDevOpsDatabase: "AzureDevOps",
        frameworkDatabase: "Framework",
        s360Server: "s360prodro",
        s360Database: "service360db",
        axeSfiServer: "axesfi.westus2",
        axeSfiDatabase: "axesfi"
    },
    ff: {
        displayName: "Fairfax",
        resourceFormat: "https://{0}.kusto.usgovcloudapi.net",
        portalServer: "azportalff",
        armServer: "armff",
        armDatabase: "armff"
    },
    mc: {
        displayName: "Mooncake",
        resourceFormat: "https://{0}.kusto.chinacloudapi.cn",
        portalServer: "azportalmc2.chinaeast2",
        armServer: "armmcadx.chinaeast2",
        armDatabase: "armmc"
    },
    usnat: {
        displayName: "USNat",
        resourceFormat: "https://{0}.kusto.core.eaglex.ic.gov",
        portalServer: "azportalusnat.usnateast",
        portalDatabase: "AzurePortal",
        portalCosmosDatabase: "AzPtlCosmos",
        armServer: "armusnat.usnateast",
        armDatabase: "armusnat",
    },
    ussec: {
        displayName: "USSec",
        resourceFormat: "https://{0}.kusto.core.microsoft.scloud",
        portalServer: "azportalussec.usseceast",
        portalDatabase: "AzurePortal",
        portalCosmosDatabase: "AzPtlCosmos",
        armServer: "armussec.usseceast",
        armDatabase: "armussec",
    },
};

export function getCloudKustoConfig(cloud: EnvironmentName): KustoConfig {
    return map[cloud];
}

export const defaultKusto = map[defaultCloud];
export const cloudKusto = cloud && map[cloud];

export function executeAzPortalKustoQuery<T1, T2, T3>(query: string, database?: keyof KustoDatabases, prodOnly?: boolean, noCache?: boolean, maxAge?: number): Promise<[T1[], T2[], T3[]]>;
export function executeAzPortalKustoQuery<T1, T2>(query: string, database?: keyof KustoDatabases, prodOnly?: boolean, noCache?: boolean, maxAge?: number): Promise<[T1[], T2[]]>;
export function executeAzPortalKustoQuery<T1>(query: string, database?: keyof KustoDatabases, prodOnly?: boolean, noCache?: boolean, maxAge?: number): Promise<T1[]>;
export function executeAzPortalKustoQuery(query: string, database?: keyof KustoDatabases, prodOnly?: boolean, noCache?: boolean, maxAge?: number): Promise<any[]> {
    return executeKustoQuery(query, "portalServer", database || "portalDatabase", prodOnly, noCache, maxAge);
}

export function executeKustoQuery<T1, T2, T3, T4>(query: string, server: keyof KustoServers, database: keyof KustoDatabases, prodOnly?: boolean, noCache?: boolean, maxAge?: number): Promise<[T1[], T2[], T3[], T4[]]>;
export function executeKustoQuery<T1, T2, T3>(query: string, server: keyof KustoServers, database: keyof KustoDatabases, prodOnly?: boolean, noCache?: boolean, maxAge?: number): Promise<[T1[], T2[], T3[]]>;
export function executeKustoQuery<T1, T2>(query: string, server: keyof KustoServers, database: keyof KustoDatabases, prodOnly?: boolean, noCache?: boolean, maxAge?: number): Promise<[T1[], T2[]]>;
export function executeKustoQuery<T1>(query: string, server: keyof KustoServers, database: keyof KustoDatabases, prodOnly?: boolean, noCache?: boolean, maxAge?: number): Promise<T1[]>;
export function executeKustoQuery(query: string, server: keyof KustoServers, database: keyof KustoDatabases, prodOnly?: boolean, noCache?: boolean, maxAge?: number): Promise<any[]> {
    return executeKustoApi("query", query, server, database, prodOnly, noCache, maxAge);
}

const multiTenantServers = new Set<keyof KustoServers>(["portalServer", "portalEuServer", "oldPortalServer", "armServer"]);

function executeKustoApi<T1, T2, T3>(apiType: string, query: string, server: keyof KustoServers, database: keyof KustoDatabases, prodOnly?: boolean, noCache?: boolean, maxAge?: number): Promise<[T1[], T2[], T3[]]>;
function executeKustoApi<T1, T2>(apiType: string, query: string, server: keyof KustoServers, database: keyof KustoDatabases, prodOnly?: boolean, noCache?: boolean, maxAge?: number): Promise<[T1[], T2[]]>;
function executeKustoApi<T1>(apiType: string, query: string, server: keyof KustoServers, database: keyof KustoDatabases, prodOnly?: boolean, noCache?: boolean, maxAge?: number): Promise<T1[]>;
function executeKustoApi(apiType: string, query: string, server: keyof KustoServers, database: keyof KustoDatabases, prodOnly?: boolean, noCache?: boolean, maxAge?: number): Promise<any[]> {
    const kustoConfig = defaultKusto;
    const resource = kustoConfig.resourceFormat.replace("{0}", kustoConfig[server] as string);

    const corpOnly = !multiTenantServers.has(server);

    return ajaxWithAuth<KustoResult>(`${resource}/v2/rest/${apiType}`, {
        settings: {
            body: JSON.stringify({
                "csl": query,
                "db": kustoConfig[database] || defaultKusto[database],
            })
        },
        scope: "https://help.kusto.windows.net/user_impersonation",
        noCache,
        maxAge: maxAge || 30 * 60 * 1000, // default cache time to 30 minutes
        corpOnly
    }).then(result => {
        const tableData = result
            .filter(item => item.FrameType === "DataTable" && item.TableKind === "PrimaryResult")
            .map(table => {
                const data = table;
                const columns = data.Columns;
                return data.Rows.map(row => {
                    const result = {} as StringMap<any>;

                    columns.forEach((column, idx) => {
                        result[column.ColumnName] = row[idx];
                    });

                    return result;
                });
            });
        return tableData.length > 1 ? tableData : tableData[0];
    });
}

export interface KustoServers {
    portalServer: string;
    portalEuServer?: string;
    oldPortalServer?: string;
    armServer: string;
    iaasTestServer?: string;
    cloudTestServer?: string;
    oneEsServer?: string;
    s360Server?: string;
    axeSfiServer?: string;
}

export interface KustoDatabases {
    armDatabase: string;
    portalDatabase?: string;
    portalCosmosDatabase?: string;
    iaasTestDatabase?: string;
    cloudTestProdDatabase?: string;
    oneEsAzureDevOpsDatabase?: string;
    frameworkDatabase?: string;
    s360Database?: string;
    axeSfiDatabase?: string;
}

export interface KustoConfig extends KustoServers, KustoDatabases {
    resourceFormat: string;
    displayName: string;
}

type KustoResult = KustoResultTable[];

interface KustoResultTable {
    FrameType: string;
    TableId: number;
    TableKind: string;
    Columns: KustoResultTableColumn[];
    Rows: KustoResultTableRow[];
}

interface KustoResultTableColumn {
    ColumnName: string;
}

interface KustoResultTableRow {
    [key: number]: any;
}
