export const FeatureFlags = {
    isCopilotDevEndpoint: "copilotdevendpoint"
}

export function isFeatureEnabled(featureFlag: string) {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get(`feature.${featureFlag}`);
    return myParam === "true";
}

export function initializeFeatureFlags() {
    const sessionStorageKey = "savedFeatureFlags";
    const savedFeatureFlags = (sessionStorage.getItem(sessionStorageKey) || "").split("|");

    let urlUpdated = false;
    const searchParams = new URLSearchParams(window.location.search);
    savedFeatureFlags.forEach(feature => {
        if (searchParams.get(feature) !== "true") {
            searchParams.set(feature, "true");
            urlUpdated = true;
        }
    });

    const newSavedFlags = Array.from(searchParams.entries())
        .filter(([key, value]) => key.startsWith("feature.") && value === "true")
        .map(([key]) => key).join("|");

    sessionStorage.setItem(sessionStorageKey, newSavedFlags);

    if (urlUpdated) {
        const url = new URL(window.location.href);
        window.history.pushState(null, '', url.toString());
    }
}
