import LRU from "lru-cache";
import { acquireToken } from "./Auth";
import { Retry } from "./Util";
import { useFirstPartyClientId } from "./Environment";

const ajaxCache = new LRU<string, Promise<Response>>({ max: 200, maxAge: 1 * 60 * 1000 }); // 1 minute cache entry time limit

interface AjaxOptions {
    settings?: RequestInit;
    scope?: string | string[];
    noCache?: boolean;
    maxAge?: number; // maximum time (in ms) that a cache entry can persist - will override the cache limit for that specific entry
    corpOnly?: boolean;
}

export async function ajaxWithAuth<T>(url: string, options?: AjaxOptions, processResponse: boolean = true): Promise<T> {
    let { settings, scope, noCache, maxAge, corpOnly } = options || {};
    const token = `Bearer ${(await acquireToken({ scopes: scope, corpOnly })).accessToken}`
    if (!token) {
        throw new Error("Token not acquired during authentication.");
    }

    settings = { method: "POST", ...settings };
    settings.headers = {
        "Authorization": token,
        "Content-Type": "application/json",
        "x-ms-app": "ExtensionAnalyzer",
        ...settings.headers
    }
    const res = await ajax(url, settings, noCache, maxAge);
    if (res.ok) {
        if (processResponse) {
            const text = await res.text();
            return text ? JSON.parse(text) : undefined as T;
        }

        return res as any;
    }

    throw res;
}

export async function ajax(url: string, init?: RequestInit, noCache?: boolean, maxAge?: number): Promise<Response> {
    const key = `${init?.method || "GET"}/${url}/${JSON.stringify(init?.body)}`;
    let promise = noCache ? null : ajaxCache.get(key);
    if (!promise) {
        if (url.startsWith("/api/")) {
            init = init || {};
            const headers = init.headers = new Headers(init.headers);
            headers.set("Authorization", `Bearer ${(await acquireToken()).accessToken}`);
        }

        promise = fetch(url, init);
        ajaxCache.set(key, promise, maxAge);
    }

    const res = await promise;
    if (!res.ok) {
        ajaxCache.del(key);
    }

    return res.clone();
}

// Impersonates a user and makes a request to given api
export async function devOpsAjax<T>(api: string, settings?: RequestInit, processResponse: boolean = true, noCache?: boolean): Promise<T> {
    settings = { method: "GET", ...settings };
    const ajaxCallback = async () => await ajaxWithAuth<T>(
        api,
        {
            settings,
            scope: devOpsScopes,
            noCache,
            corpOnly: true,
        },
        processResponse);
    const errorMessage = "An error occurred while making a request";
    const errorCallback = async (err: any) => { const error = await err.json(); return new Error(error.message ?? error) };
    return await Retry(ajaxCallback, errorMessage, errorCallback);
}

const devOpsScopes = (useFirstPartyClientId
    ? [
        "vso.code_write",
        "vso.build_execute",
        "vso.release_execute",
        "vso.work_write",
        "vso.test",
        "vso.packaging"
    ] : ["user_impersonation"]).map((scope) => `499b84ac-1321-427f-aa17-267ca6975798/${scope}`);
