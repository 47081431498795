import { useMsal } from '@azure/msal-react';
import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { cloudKusto } from '../Common/Kusto';
import { DstsPanel } from "./DstsPanel";
import { AccountsPanel } from "./AccountsPanel";
import { useBoolean } from '@fluentui/react-hooks';
import { DefaultButton, IButtonStyles, IconButton, mergeStyleSets, Stack } from '@fluentui/react';
import { DataAccessContext } from '../Contexts/DataAccessContext';

interface Props {
  title: string;
}

const TopBar = (props: Props) => {
  const { title } = props;
  const { accounts } = useMsal();
  const { activeAccount } = useContext(DataAccessContext);
  const username = useMemo(() => {
    if (!accounts.length) {
      return "Logging in";
    }

    let text = activeAccount?.username;
    if (accounts.length > 1) {
      text += ` (+${accounts.length - 1})`;
    }

    return text;
  }, [accounts, activeAccount]);
  const [showSwitcher, { setTrue: openSwitcher, setFalse: closeSwitcher }] = useBoolean(false);
  const [showAccounts, { setTrue: openAccounts, setFalse: closeAccounts }] = useBoolean(false);

  return (
    <>
      <Stack horizontal className={classNames.topBar} verticalAlign="center" horizontalAlign="space-between">
        <Stack.Item>
          <h1 className={classNames.home}><Link to="/">Extension Analyzer</Link></h1>
        </Stack.Item>
        <Stack.Item grow={1}>
          <span className={classNames.title}>{title}</span>
        </Stack.Item>
        <Stack.Item>
          <IconButton
            iconProps={{ iconName: "Bug" }}
            href="https://aka.ms/portalfx/extensionanalyzerbug"
            target='_blank'
            styles={buttonStyles}
            title='Report a bug' />
        </Stack.Item>
        <Stack.Item>
          <IconButton
            iconProps={{ iconName: "Globe" }}
            onClick={openSwitcher}
            styles={buttonStyles}
            title={cloudKusto.displayName} />
        </Stack.Item>
        <Stack.Item>
          <DefaultButton
            iconProps={{ iconName: "Settings" }}
            onClick={openAccounts}
            styles={buttonStyles}>
            <Stack horizontalAlign="start" className={classNames.usernameStack}>
              <Stack.Item>
                <span className={classNames.username}>{username}</span>
              </Stack.Item>
              <Stack.Item>
                <span className={classNames.manageAccounts}>Manage accounts</span>
              </Stack.Item>
            </Stack>
          </DefaultButton>
        </Stack.Item>
      </Stack>
      {showSwitcher ? (
        <DstsPanel
          dismissPanel={closeSwitcher}
        />
      ) : null}
      {showAccounts ? (
        <AccountsPanel
          dismissPanel={closeAccounts}
        />
      ) : null}
    </>
  );
};

const buttonStyles: IButtonStyles = {
  root: {
    backgroundColor: "transparent",
    color: "inherit",
    border: "none"
  },
  rootHovered: {
    color: "inherit",
    backgroundColor: "#106ebe"
  }
}

const classNames = mergeStyleSets({
  topBar: {
    backgroundColor: "#0078d4",
    color: "#ffffff",
    fontSize: 15
  },
  home: {
    fontSize: 15,
    padding: "0 15px",
    "> a": {
      color: "#ffffff",
      textDecoration: "none",
    }
  },
  title: {
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  manageAccounts: {
    fontSize: 10,
    fontWeight: 600,
    textTransform: "uppercase",
  },
  username: {
    fontSize: 14
  },
  usernameStack: {
    paddingLeft: 5
  }
});

export default React.memo(TopBar);
