import { Dropdown, IDropdownOption, mergeStyleSets, Panel, PanelType, PrimaryButton, Stack, StackItem, TextField } from "@fluentui/react";
import { useMemo, useState } from "react";
import KustoLinks from "../Components/KustoLinks";

export const DstsPanel = (props: {
    dismissPanel: () => void;
}) => {
    const [env, setEnv] = useState<IDropdownOption<EnvironmentName>>();
    const [sessionIdInput, setSessionIdInput] = useState<string>();
    const [sessionId, setSessionId] = useState<string>();
    const cloud = useMemo(() => env?.data, [env]);
    return (
        <Panel
            headerText="Sovereign Cloud Temporarily Not Available"
            isOpen
            onDismiss={props.dismissPanel}
            closeButtonAriaLabel="Close"
            type={PanelType.medium}
            isLightDismiss
        >
            <Stack tokens={stackTokens} styles={stackStyles}>
                <p>For security reasons, dSTS authentication from Extension Analyzer to Fairfax and Mooncake is currently unavailable. We are waiting for dSTS to deliver support for OBO flow over MSAL.js for SPAs.</p>
                <p>You can continue to query Portal and ARM telemetry in sovereign clouds with Kusto Explorer. See <a href="https://eng.ms/docs/products/azure-portal-framework-ibizafx/telemetry/supported-datastores" target="_blank" rel="noreferrer">Data Store Information</a> in portal documentation for access.</p>
                <p>For concerns and questions, please send via the "Report a bug" button.</p>
                <h3 className={classNames.header}>Starter queries</h3>
                <p>For your convenience, here are some starter queries for common scenarios:</p>
                <Stack.Item>
                    <Dropdown
                        label="Cloud"
                        options={envOptions}
                        selectedKey={env?.key}
                        placeholder="Select a cloud..."
                        onChange={(_, option) => setEnv(option)}
                    />
                </Stack.Item>
                {cloud ?
                    (
                        <ul className={classNames.list}>
                            <li>
                                <Stack tokens={stackTokens}>
                                    <h4 className={classNames.header}>Session Telemetry</h4>
                                    <Stack horizontal tokens={stackTokens} verticalAlign="end">
                                        <StackItem grow>
                                            <TextField
                                                label="Session ID"
                                                value={sessionIdInput}
                                                onChange={(_, value) => setSessionIdInput(value)}
                                                placeholder="Enter a session ID"
                                            />
                                        </StackItem>
                                        <StackItem>
                                            <PrimaryButton
                                                text="Get queries"
                                                onClick={() => {
                                                    setSessionId(sessionIdInput);
                                                }}
                                                disabled={!sessionIdInput}
                                            />
                                        </StackItem>
                                    </Stack>
                                    {sessionId ? (
                                        <ul className={classNames.list}>
                                            <li>
                                                Get session info (start time, user, portal URI)
                                                <KustoLinks
                                                    server={"portalServer"}
                                                    database={"portalDatabase"}
                                                    cloud={cloud}
                                                    query={`SvcSessions
| where sessionId == "${sessionId}"
| summarize minTime = min(PreciseTimeStamp), take_anyif(query, isnotempty(query)), take_any(requestUri) by tenantId, userTypeHint, userAgent, email, userId, clientVersion`}
                                                />
                                            </li>
                                            <li>
                                                Get session CES/CVA feedback
                                                <KustoLinks
                                                    server={"portalServer"}
                                                    database={"portalDatabase"}
                                                    cloud={cloud}
                                                    query={`ClientTelemetry
| where action == "InProductFeedbackSubmit" and userTypeHint == ""
| where sessionId == "${sessionId}"
| project PreciseTimeStamp, data
| extend dataJson = parse_json(data)
| extend Extension = tostring(dataJson.extensionName)
| extend Blade = tostring(dataJson.bladeName)
| extend MayContact = tostring(dataJson.mayContact)
| extend FeatureName = tostring(dataJson.featureName)
| extend Value = round(100 * toint(dataJson.CVAValue) / 10)
| extend Effort = round(100 * (toint(dataJson.CESValue) -1) / 6)
| extend Overall = (Value + Effort) / 2
| extend Comment = tostring(dataJson.comments)
| extend Feature = tostring(dataJson.featureName) , Survey = tostring(dataJson.surveyId)
| project-away data, dataJson
| project Blade, FeatureName, Value, Effort, Overall, Comment, MayContact, Extension, PreciseTimeStamp
| order by PreciseTimeStamp asc`}
                                                />
                                            </li>
                                            <li>
                                                Get session send-a-smile feedback
                                                <KustoLinks
                                                    server={"portalServer"}
                                                    database={"portalDatabase"}
                                                    cloud={cloud}
                                                    query={`ClientTelemetry
| where sessionId == "${sessionId}" 
| where action == "GenericFeedback" 
| extend dataJson = parsejson(['data']) 
| extend extensionName = tostring(dataJson.currentBladeInfo.extensionName) 
| project PreciseTimeStamp, emotion = dataJson.emotion, mayContact = dataJson.mayContact, comments = dataJson.comments, extensionName, sessionId 
| join (
  SvcSessions | summarize userAgent = any(userAgent), any(email) by sessionId 
) on sessionId 
| extend email = iff(mayContact =~ "true", any_email, "") 
| project-away sessionId, sessionId1, any_email`}
                                                />
                                            </li>
                                            <li>
                                                Get session initialized extensions
                                                <KustoLinks
                                                    server={"portalServer"}
                                                    database={"portalDatabase"}
                                                    cloud={cloud}
                                                    query={`ClientTelemetry 
| where sessionId == "${sessionId}"
| where action == "ExtensionLoad"
| extend dataJson = parsejson(['data'])
| mvexpand shellSide = dataJson.shellSide
| extend bootExtVersion = tostring(shellSide.bootGetMessage.extVersion)
| extend extVersion = iff(isempty(bootExtVersion), extract("&pageVersion=([^&]*)&", 1, tostring(shellSide.uriUsed)), bootExtVersion)
| extend totalTime = toint(duration)
| summarize attempts = count(), extVersion = max(extVersion), totalTime = max(totalTime) by clientTime, clientTimeZone, name, actionModifier
| order by clientTime`}
                                                />
                                            </li>
                                            <li>
                                                Get session user actions
                                                <KustoLinks
                                                    server={"portalServer"}
                                                    database={"portalDatabase"}
                                                    cloud={cloud}
                                                    query={`let commandMap = parse_json("{'\\"0\\"':'Abort','\\"1\\"':'Cancel','\\"2\\"':'Ignore','\\"3\\"':'No','\\"4\\"':'Ok','\\"5\\"':'Retry','\\"6\\"':'Yes'}");
ClientTelemetry
| where sessionId == "${sessionId}"
| where actionModifier != "start"
| extend action = iff(action == "Get" and source == "Services.SecurityTokens", "GetSecurityToken", action)
| where action in (
        "ActionBarClicked",
        "AvatarMenuOpen",
        "AvatarMenuOpenBilling",
        "AvatarMenuOpenFeedback",
        "AvatarMenuOpenAccessDetails",
        "AvatarMenuOpenContactInfo",
        "AvatarMenuSignOut",
        "AvatarMenuSwitchTenant",
        "BladeClosed",
        "BladeFullReady",
        "BladeLoadErrored",
        "CommandExecuted",
        "CreateDeploymentEnd",
        "CreateDeploymentPreflight",
        "CreateDeploymentStart",
        "DashboardRevealed",
        "DialogClosed",
        "DialogOpened",
        "GetSecurityToken",
        "MenuBladeRevealed",
        "MenuItemClick",
        "SettingsPaneOpen"
    )
| extend dataJson = parsejson(['data'])
| extend isNameBladeLocator = action == "BladeClosed" or action == "BladeFullReady" or action == "DialogClosed" or action == "DialogOpened" or action == "MenuBladeRevealed"
| extend itemName = iff(action == "DashboardRevealed", tostring(dataJson.dashboardId), iff(isNameBladeLocator, tostring(split(name, "/")[3]), iff(action == "GetSecurityToken", strcat(name, " [", ["data"], "]"), name)))
| extend clickResult = iff(action == "DialogClosed", iff(commandMap contains ['data'], tostring(commandMap[['data']]), extract('"(.+)"', 1, ['data'])), "[Unknown]")
| project
    PreciseTimeStamp,
    clientTime,
    extension,
    action,
    actionModifier,
    source,
    itemName,
    clickResult,
    resource = dataJson["resource"],
    galleryItemId = dataJson["galleryItemId"],
    subscriptionId = dataJson["subscriptionId"],
    telemetryId = dataJson["telemetryId"],
    tenantId,
    ActivityId
| union (
    ExtTelemetry 
    | where sessionId == "${sessionId}"
    | where actionModifier != "start"
    | where action in (
            "CreateFlowLaunched"
        )
    | extend itemName = name 
    | extend dataJson = parsejson(['data'])
    | project
        PreciseTimeStamp,
        clientTime,
        extension,
        action,
        actionModifier,
        source,
        itemName,
        clickResult = "",
        resource = dataJson["resource"],
        galleryItemId = dataJson["galleryItemId"],
        subscriptionId = dataJson["subscriptionId"],
        telemetryId = dataJson["telemetryId"],
        tenantId,
        ActivityId
)
| order by clientTime asc `}
                                                />
                                            </li>
                                            <li>
                                                Get session framework errors
                                                <KustoLinks
                                                    server={"portalServer"}
                                                    database={"portalDatabase"}
                                                    cloud={cloud}
                                                    query={`ClientEvents 
| where sessionId == "${sessionId}"
| project PreciseTimeStamp, extension, area, message, eventLevel, ActivityId, sessionId`}
                                                />
                                            </li>
                                            <li>
                                                Get session extension errors
                                                <KustoLinks
                                                    server={"portalServer"}
                                                    database={"portalDatabase"}
                                                    cloud={cloud}
                                                    query={`ExtEvents
| where sessionId == "${sessionId}"
| project PreciseTimeStamp, extension, area, message, clientVersion, eventLevel, ActivityId, sessionId`}
                                                />
                                            </li>
                                            <li>
                                                Get session ARM failed errors
                                                <KustoLinks
                                                    server={"armServer"}
                                                    database={"armDatabase"}
                                                    cloud={cloud}
                                                    query={`HttpIncomingRequests
| where clientSessionId == "${sessionId}"
| where httpStatusCode >= 400
| project PreciseTimeStamp, httpMethod, operationName, httpStatusCode, durationInMilliseconds, contentLength, apiVersion, ActivityId, subscriptionId, correlationId
| order by PreciseTimeStamp asc`}
                                                />
                                            </li>
                                        </ul>
                                    ) : null}
                                </Stack>
                            </li>
                        </ul>
                    ) : null}

            </Stack>
        </Panel>
    );
}

const envOptions: IDropdownOption<EnvironmentName>[] = [
    { key: "Fairfax", text: "Fairfax", data: "ff" },
    { key: "Mooncake", text: "Mooncake", data: "mc" },
];

const stackTokens = { childrenGap: 10 };
const stackStyles = { root: { marginTop: 10 } };

const classNames = mergeStyleSets({
    list: {
        listStyleType: "initial",
        marginLeft: 20
    },
    header: {
        fontWeight: 600,
        marginTop: 20,
        marginBottom: 0
    }
});
