import 'purecss/build/pure.css';
import React, { PropsWithChildren } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { Icons } from '../Common/Icons';
import './Layout.css';
import SideBar from './SideBar';
import './SvgStyles.css';
import TopBar from './TopBar';

interface Props {
  title: string;
}

const Layout = (props: PropsWithChildren<Props>) => {
  React.useEffect(() => {
    document.title = props.title
  }, [props.title]);

  return (
    <Router>
      <>
        <div className="fxs-portal">
          <TopBar title={props.title} />
          <div className="fxs-body-container">
            <SideBar />
            <div className="fxs-body">
              {props.children}
            </div>
          </div>
        </div>
        {Icons.indicators}
      </>
    </Router>
  );
};

export default React.memo(Layout);
