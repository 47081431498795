import React from 'react';
import { cloud, cloudDisplayName, defaultCloud } from '../Common/Environment';
import Banner, { BannerValue } from '../Components/Banner';
import { useParams } from 'react-router-dom';
import { Icons } from '../Common/Icons';
import { ajaxWithAuth } from '../Common/Ajax';
import Grid, { Column, Align } from '../Components/Grid';
import { getFriendlyVersionName } from '../Common/Deployments';

interface Params {
  productName: string;
}

const ProductsOverview = () => {
  const { productName } = useParams<Params>();
  const [banner, setBanner] = React.useState<BannerValue>();
  const [showAllPerfColumns, setShowAllPerfColumns] = React.useState(false);
  const [response, setResponse] = React.useState<ProductResponse>();
  React.useEffect(() => {
    setBanner({ text: resources.loadingLabel, svg: Icons.loading });

    ajaxWithAuth<ProductResponse>(`/api/products/${productName}?env=${cloud}`).then(res => {
      setResponse(res)
      setBanner(undefined);
    }).catch(error => {
      setBanner({ svg: Icons.failure, error });
    });
  }, [productName]);
  const dataFreshness = response && resources.dataFreshnessFormat.replace("{0}", response.endTime);
  const columns = getGridColumns(showAllPerfColumns);
  return (
    <>
      <Banner value={banner} />
      <div className="fxs-tab-section fxs-wrapitems">
        <div className="fxs-tab-section-field">
          <div className="fxs-tab-section-field-label" id="product-overview-environment">{resources.environmentLabel}</div>
          <select className="fxs-tab-section-field-value" aria-labelledby="product-overview-environment" value="prod" disabled>
            <option value={cloud}>{cloudDisplayName}</option>
          </select>
        </div>
        <div className="fxs-tab-section-field">
          <input type="checkbox" aria-labelledby="product-overview-allperfcolumns" checked={showAllPerfColumns} onChange={e => setShowAllPerfColumns(e.target.checked)} />
          <div id="product-overview-allperfcolumns">{resources.showAllPerfColumnsLabel}</div>
        </div>
      </div>
      <section className="fxs-tab-section">
        <h2 style={{ margin: 0 }}>{resources.extensionsSectionHeader}</h2>
        <p style={{ margin: "8px 0" }}>{dataFreshness}</p>
        <Grid
          columns={columns}
          rows={response?.extensions}
          altRows={true} />
      </section>
    </>
  );
};

function getGridColumns(showAllPerfColumns: boolean) {
  const hasUsage = cloud === defaultCloud;
  const gridColumns: Column<ProductResponseExtension>[] = [
    {
      headerText: resources.extensionsGridHeaders.extensionName,
      key: row => (<a href={`/extensions/${row.extensionName}`}>{row.extensionName}</a>),
      sortKey: row => row.extensionName
    },
    {
      headerText: resources.extensionsGridHeaders.sdkVersion,
      key: row => `${getFriendlyVersionName(row.sdkVersion)}`,
    },
    {
      headerText: resources.extensionsGridHeaders.extensionLoadCount,
      key: row => row.extensionLoadCount,
      align: Align.Right,
      visible: hasUsage
    },
    {
      headerText: resources.extensionsGridHeaders.uniqueUserCount,
      key: row => row.uniqueUserCount,
      align: Align.Right,
      visible: hasUsage
    },
  ];

  showAllPerfColumns = hasUsage && showAllPerfColumns;
  if (showAllPerfColumns) {
    gridColumns.push(
      {
        headerText: resources.extensionsGridHeaders.extensionLoad50th,
        key: row => row.duration50,
        getCss: row => row.duration50 < 500 ? "fxs-controls-grid-cell-success" : "fxs-controls-grid-cell-failure",
        align: Align.Right,
      },
      {
        headerText: resources.extensionsGridHeaders.extensionLoad80th,
        key: row => row.duration80,
        getCss: row => row.duration80 < 1000 ? "fxs-controls-grid-cell-success" : "fxs-controls-grid-cell-failure",
        align: Align.Right,
      });
  }

  gridColumns.push({
    headerText: resources.extensionsGridHeaders.extensionLoad95th,
    key: row => row.duration95,
    getCss: row => row.duration95 < 2000 ? "fxs-controls-grid-cell-success" : "fxs-controls-grid-cell-failure",
    align: Align.Right,
    visible: hasUsage
  });

  if (showAllPerfColumns) {
    gridColumns.push({
      headerText: resources.extensionsGridHeaders.extensionLoad99th,
      key: row => row.duration99,
      getCss: row => row.duration99 < 4000 ? "fxs-controls-grid-cell-success" : "fxs-controls-grid-cell-failure",
      align: Align.Right,
    });
  }

  gridColumns.push(
    {
      headerText: resources.extensionsGridHeaders.icmService,
      key: row => row.icmService,
    },
    {
      headerText: resources.extensionsGridHeaders.icmTeam,
      key: row => row.icmTeam,
    },
    {
      headerText: resources.extensionsGridHeaders.feedbackEmail,
      key: row => (<a href={`mailto: ${row.feedbackEmail}`}>{row.feedbackEmail}</a>),
      sortKey: row => row.feedbackEmail
    });
  return gridColumns;
}

const resources = {
  loadingLabel: "Downloading product information",
  environmentLabel: "Environment",
  extensionsSectionHeader: "Extensions",
  extensionsGridHeaders: {
    extensionName: "Extension",
    extensionLoadCount: "Load count",
    uniqueUserCount: "Total unique users",
    extensionLoad50th: "Load 50th",
    extensionLoad80th: "Load 80th",
    extensionLoad95th: "Load 95th",
    extensionLoad99th: "Load 99th",
    hostingService: "Hosting services",
    sdkVersion: "SDK",
    icmService: "ICM service",
    icmTeam: "ICM team",
    feedbackEmail: "Feedback email",
  },
  showAllPerfColumnsLabel: "Show additional performance information",
  dataFreshnessFormat: `Usage for week ending {0}`,
};


export default React.memo(ProductsOverview);
