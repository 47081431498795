import { BlobServiceClient, UserDelegationKey } from "@azure/storage-blob";
import { corpTokenCredential } from "./Auth";
import { ajax } from "./Ajax";

const blobServiceCache = new Map<string, BlobServiceClient>();

const userDelegationKeyCache = new Map<BlobServiceClient, { keyPromise: Promise<UserDelegationKey>, expiryMs: number }>();
const keyExpiryDays = 6;
const keyExpiryBufferDays = 1;

const containerSasCache = new Map<string, { sasPromise: Promise<string>, expiryMs: number }>();
const containerSasExpiryHours = 8;
const containerSasExpiryBufferHours = 1;

export function getBlobService(accountName: string) {
    const blobEndpoint = `https://${accountName}.blob.core.windows.net`;
    if (!blobServiceCache.has(blobEndpoint)) {
        blobServiceCache.set(blobEndpoint, new BlobServiceClient(blobEndpoint, corpTokenCredential));
    }

    return blobServiceCache.get(blobEndpoint)!;
}

async function getUserDelegationKey(blobService: BlobServiceClient) {
    if (userDelegationKeyCache.has(blobService)) {
        const { keyPromise, expiryMs } = userDelegationKeyCache.get(blobService)!;
        if (expiryMs > Date.now()) {
            return keyPromise;
        }

        userDelegationKeyCache.delete(blobService);
    }

    const cacheExpiryDate = new Date();
    cacheExpiryDate.setDate(cacheExpiryDate.getDate() + keyExpiryDays - keyExpiryBufferDays);
    const keyPromise = (async () => {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 1);
        const endDate = new Date();
        endDate.setDate(endDate.getDate() + keyExpiryDays);
        const { signedObjectId, signedTenantId, signedStartsOn, signedExpiresOn, signedService, signedVersion, value } = await blobService.getUserDelegationKey(startDate, endDate);
        return { signedObjectId, signedTenantId, signedStartsOn, signedExpiresOn, signedService, signedVersion, value } as UserDelegationKey;
    })();
    userDelegationKeyCache.set(blobService, { keyPromise, expiryMs: cacheExpiryDate.getTime() });
    return keyPromise;
}

export async function getBlobContainerDelegationSas(accountName: string, containerName: string) {
    const cacheKey = `${accountName}/${containerName}`;
    if (containerSasCache.has(cacheKey)) {
        const { sasPromise, expiryMs } = containerSasCache.get(cacheKey)!;
        if (expiryMs > Date.now()) {
            return sasPromise;
        }

        containerSasCache.delete(cacheKey);
    }

    const cacheExpiryDate = new Date();
    cacheExpiryDate.setHours(cacheExpiryDate.getHours() + containerSasExpiryHours - containerSasExpiryBufferHours);
    const sasPromise = (async () => {
        const blobService = getBlobService(accountName);
        const userDelegationKey = await getUserDelegationKey(blobService);
        const expiresOn = new Date();
        expiresOn.setHours(expiresOn.getHours() + containerSasExpiryHours);
        const res = await ajax("/api/storage/getContainerDelegationSas", {
            method: "POST",
            body: JSON.stringify({
                userDelegationKey,
                accountName,
                containerName,
                expiresOn
            }),
            headers: {
                "Content-Type": "application/json",
            }
        });
        const { sasParams } = await res.json();
        return sasParams;
    })();
    containerSasCache.set(cacheKey, { sasPromise, expiryMs: cacheExpiryDate.getTime() });
    return sasPromise;
}

export async function getBlobText(accountName: string, containerName: string, blobPath: string) {
    const response = await getBlobService(accountName).getContainerClient(containerName).getBlobClient(blobPath).download();
    const blob = await response.blobBody;
    return await blob!.text();
}
