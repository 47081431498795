import './SideBar.css';
import React, { memo } from 'react';
import { dashboardItems, diagnosticsItems, iaasItems, noticeItems, portalItems, SidebarItem } from './SideBarItems';
import { Link } from 'react-router-dom';
import { mergeStyleSets } from '@fluentui/react';

const sideBarItems = dashboardItems.concat(diagnosticsItems, portalItems, iaasItems);

const SideBarItem = memo((props: { item: SidebarItem }) => {
  const { item } = props;
  const iconAndText = (
    <>
      <span>{item.svg}</span>
      <span className="fxs-sidebar-item-text" id={item.id}>{item.text}</span>
    </>);
  return (
    item.hidden !== true ? (<li className="fxs-sidebar-item" title={item.text}>
      {item.openExternal
        ? <a href={item.link} target="_blank" rel="noopener noreferrer">{iconAndText}</a>
        : <Link to={item.link}>{iconAndText}</Link>}
    </li>) : null
  );
});

const SideBar = () => {
  const [showSideBar, setShowSideBar] = React.useState(false);
  const toggleSideBar = () => {
    setShowSideBar(!showSideBar);
  };
  let sideBarClassName = "fxs-sidebar";
  if (!showSideBar) {
    sideBarClassName += " fxs-sidebar-collapsed"
  }

  return (
    <div className={sideBarClassName}>
      <div className="fxs-sidebar-item fxs-sidebar-expander-container" onClick={toggleSideBar} title="Expand/Collapse">
        <svg viewBox="0 0 50 50" className="fxs-sidebar-item-icon fxs-sidebar-expander" role="presentation" focusable="false" width="100%" height="100%">
          <g>
            <title></title>
            <path d="M19.432 22.112h27.119v6.307H19.432zm0 13.875h27.119v6.307H19.432zm0-27.75h27.119v6.307H19.432z" className="msportalfx-svg-c04"></path>
            <circle opacity=".6" cx="6.818" cy="39.152" r="3.784" className="msportalfx-svg-c15"></circle>
            <circle opacity=".6" cx="6.818" cy="11.907" r="3.784" className="msportalfx-svg-c15"></circle>
            <circle cx="6.818" cy="25.53" r="3.784" className="msportalfx-svg-c16"></circle>
          </g>
        </svg>
      </div>
      <ul className="fxs-sidebar-list">{sideBarItems.map(item => <SideBarItem item={item} key={item.id} />)}</ul>
      <ul className={`fxs-sidebar-list ${classNames.sideBarListBottom}`}>{noticeItems.map(item => <SideBarItem item={item} key={item.id} />)}</ul>
      <div className="fxs-sidebar-shadow"></div>
    </div >
  );
};

const classNames = mergeStyleSets({
  sideBarListBottom: {
    position: 'absolute',
    bottom: '0',
  }
});

export default React.memo(SideBar);
