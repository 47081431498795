import React, { useContext } from 'react';
import { Icons } from '../Common/Icons';
import { cloud } from '../Common/Environment';
import { ajaxWithAuth } from '../Common/Ajax';
import Banner, { BannerValue } from '../Components/Banner';
import { executeAzPortalKustoQuery } from '../Common/Kusto';
import { useParams } from 'react-router-dom';
import { ChartConfiguration } from 'chart.js';
import ChartJs, { addTrendline, chartColors, color } from '../Components/ChartJs';
import { DataAccessContext } from '../Contexts/DataAccessContext';
import { getPortalQuery, getPortalTable } from '../Common/PortalQueries';

interface Params {
  productName: string;
}

const ProductClientErrors = () => {
  const { productName } = useParams<Params>();
  const [banner, setBanner] = React.useState<BannerValue>();
  const [chart, setChart] = React.useState<ChartConfiguration>();
  const { activeAccount } = useContext(DataAccessContext);
  React.useEffect(() => {
    setBanner({ text: resources.loadingLabel, svg: Icons.loading });
    ajaxWithAuth<ProductResponse>(`/api/products/${productName}?env=${cloud}`).then(res => {
      const query = getPortalQuery({
        baseQuery: `${getPortalTable({ database: "AzurePortal", table: "ExtEvents", activeAccount })}
| where eventLevel == 2 and userTypeHint == ""
| where ${res.extensions.map(ext => `extension == "${ext.extensionName}"`).join(" or ")} 
| summarize errorCount = count(), uniqueUserCount = dcount(userId, 2) by bin(PreciseTimeStamp, 1d)`,
        groupQuery: `| summarize errorCount = sum(errorCount), uniqueUserCount = sum(uniqueUserCount) by bin(PreciseTimeStamp, 1d)
| order by PreciseTimeStamp asc`,
        activeAccount,
      });
      return executeAzPortalKustoQuery<ErrorCountsItem>(query);
    }).then(chartItems => {
      setChart({
        plugins: [{
          beforeDraw: chartInstance => addTrendline(chartInstance, { yAxisId: "y-axis-users" }),
        }],
        type: "line",
        data: {
          labels: chartItems.map(c => c.PreciseTimeStamp),
          datasets: [
            {
              label: resources.chart.seriesLabels.affectedUsers,
              backgroundColor: color(chartColors.blue).alpha(0.5).rgbString(),
              borderColor: chartColors.blue,
              fill: false,
              data: chartItems.map(item => item.uniqueUserCount),
              yAxisID: "y-axis-users",
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [{
              type: "time",
              scaleLabel: {
                display: true,
                labelString: resources.chart.xAxisLabel,
              },
            }],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: resources.chart.yAxisLabelUsers,
                  fontColor: chartColors.blue,
                },
                position: "left",
                id: "y-axis-users",
              },
            ],
          }
        }
      });
      setBanner(undefined);
    }).catch(error => {
      setBanner({ svg: Icons.failure, error });
    });
  }, [activeAccount, productName]);
  return (
    <>
      <Banner value={banner} />
      <section className="fxs-tab-section">
        <h3 style={{ marginTop: 0 }}>{resources.sectionHeader}</h3>
        <div>
          <ChartJs className="product-clienterrors-chart-canvas" style={{ width: "100%", height: "240px" }} config={chart} />
        </div>
      </section>
    </>
  );
};

interface ErrorCountsItem {
  PreciseTimeStamp: string;
  uniqueUserCount: number;
  errorCount: number;
}

const resources = {
  sectionHeader: "Customers with one or more client errors",
  loadingLabel: "Downloading error information",
  chart: {
    xAxisLabel: "Timestamp",
    yAxisLabelErrors: "Error counts",
    yAxisLabelUsers: "Affected users",
    seriesLabels: {
      errors: "Error counts",
      affectedUsers: "Affected users",
    },
  },
};

export default React.memo(ProductClientErrors);
